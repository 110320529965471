import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import "./log.scss";

const Log = ({ show, onClose, serverLogs }) => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    if (Array.isArray(serverLogs) && serverLogs.length > 0) {
      setLogs((prevLogs) => [...prevLogs, ...serverLogs]);
    }
  }, [serverLogs]);

  return (
    <div className={`log-modal ${show ? "show" : ""}`}>
      <div className="log-modal-content">
        <div className="log-header">
          <h3>Validation Logs</h3>
          <IoClose className="close-icon" onClick={onClose} />
        </div>
        <div className="log-body">
          {logs.map((log, index) => (
            <div key={index} className={`log-item log-${log.status}`}>
              <span className="log-message">{log.message}</span>
              <span className="log-status">{log.status}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Log;
