const initialState = {
    gameData: "",
    loading: false,
    error: null,
    status: false ,
    tryAgain :false,
    userCode :"",
    editor : false,
  };
  
  const gameReducer = (state = initialState, action) => {
    switch (action.type) {
      case "BUGGYCODE_FETCH_REQUEST":
        return { ...state, loading: true, error: null };
      case "BUGGYCODE_FETCH_SUCCESS":
        return { ...state, loading: false,  editor : true , gameData: action.payload.data , status: action.payload.status, };
      case "WRITE_FETCH_FAILURE":
        return { ...state, loading: false, error: action.payload };
        case "WRITE_FETCH_REQUEST":
        return { ...state, loading: true, error: null };
      case "WRITE_FETCH_SUCCESS":
        return { ...state, loading: false,editor : true ,  gameData: action.payload.data , status: action.payload.status, };
        case 'BUGGYCODE_FETCH_FAILURE':
          return { ...state, loading: false, error: action.payload.message };
          case "OPTIMIZE_FETCH_REQUEST":
            return { ...state, loading: true, error: null };
        case "OPTIMIZE_FETCH_SUCCESS":
        return { ...state, loading: false,editor : true , gameData: action.payload.data , status: action.payload.status, };
        case 'UPDATE_NAVBAR_QUESTION':
            return {
                ...state,
                gameData: {
                    ...state.gameData,
                    question: action.payload, 
                },
            };
            case 'SET_GAME_STATUS_FALSE':
      return {
        ...state,
        status: false, 
      };
    case 'CLEAR_GAME_DATA':
      return {
        ...state,
        gameData: "", 
      };

      case 'TRY_AGAIN':
        return {
          ...state,
          tryAgain: true, 
        };
        case 'USER_CODE':
          return {
            ...state,
            userCode: action.payload, 
          };
       
      default:
        return state;
    }
  };
  
  export default gameReducer;
  