import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Sidebar from '../../components/Sidebar/Sidebar';
import Navbar from '../../components/Navbar/Navbar';
import './verification.scss';
import url from '../../components/endpoint';
import { Bars } from 'react-loader-spinner';

const Verification = ({ showSidebar, active, closeSidebar }) => {
  const [message, setMessage] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const { email, emailVerified } = useSelector((state) => state.user.userData) || {};

  const handleSubmit = async (url, method, data, successMessage, errorMessage) => {
    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      const response = await axios({
        method,
        url,
        data,
        withCredentials: true,
        headers: { Authorization: `Bearer ${token}` },
      });
      setMessage(successMessage);
      return response;
    } catch (error) {
      const errorMsg = error.response?.data?.error || errorMessage;
      setMessage(errorMsg);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setMessage('Email not available.');
      return;
    }

    try {
      await handleSubmit(
        `${url}/otp/emailVerification`,
        'get',
        { userEmail: email },
        'OTP sent to your email. Please check your inbox.',
        'Failed to generate OTP. Please try again.'
      );
      setOtpSent(true);
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();

    try {
      await handleSubmit(
        `${url}/otp/verifyOTP`,
        'post',
        { userEmail: email, otp },
        'OTP verified successfully. Your Account is Verified.',
        'Failed to verify OTP.'
      );
      setOtpVerified(true);
    } catch (error) {
      console.error('Error verifying OTP:', error);
    }
  };

  const handleSubmitVerification = (e) => {
    e.preventDefault();
    setMessage('Verification submitted successfully!');
  };

  if (emailVerified) {
    return (
      <div className="verification-wrapper">
        <Sidebar active={active} closeSidebar={closeSidebar} />
        <div className="verification-container">
          <Navbar showSidebar={showSidebar} />
          {/* Display the GIF from the local path */}
          <div className="verification-gif">
            <img src="/verifi.gif" alt="Verification Successful" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="verification-wrapper">
      <Sidebar active={active} closeSidebar={closeSidebar} />
      <div className="verification-container">
        <Navbar showSidebar={showSidebar} />
        <h2>Verification Required</h2>
        <p>Please verify your email.</p>

        <form
          onSubmit={otpVerified ? handleSubmitVerification : otpSent ? handleVerifyOtp : handleEmailSubmit}
          className="verification-form"
        >
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              value={email || ''}
              readOnly
              required
              placeholder="Enter your email"
            />
          </div>

          {loading && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <Bars height="80" width="80" color="#fff" ariaLabel="bars-loading" visible={true} />
            </div>
          )}

          {otpSent && !otpVerified && (
            <div className="form-group">
              <label htmlFor="otp">Enter OTP</label>
              <input
                type="text"
                id="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
                placeholder="Enter OTP"
                className="otp-input"
              />
            </div>
          )}
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading
              ? 'Processing...'
              : otpVerified
              ? 'Submit Verification'
              : otpSent
              ? 'Verify Email'
              : 'Send OTP'}
          </button>
        </form>

        {message && <div className="verification-message">{message}</div>}
      </div>
    </div>
  );
};

export default Verification;
