import React, { useState } from 'react';
import './loading.scss';

const Loading = ({ message, showModal, onClose }) => {
  if (!showModal) return null;

  return (
    <div className="loading-overlay" onClick={onClose}>
      <div className="loading-content" onClick={(e) => e.stopPropagation()}>
        <img 
          src="https://img.icons8.com/?size=100&id=77&format=png&color=228BE6" 
          alt="Icon" 
          className="loading-icon" 
        />
        <p className="loading-message">{message}</p>
       
        <div className="loading-spinner"></div>
      </div>
    </div>
  );
};

export default Loading;
