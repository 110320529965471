import React, { useState, useEffect } from 'react';
import './deposits.scss';
import axios from 'axios';
import url from '../endpoint';
import Loading from '../Loading/loading';  
import { useSelector } from 'react-redux';

const Deposits = ({ paymentMethod, fullName = '', surname = '', email = '', walletAddress = '', accountNumber = '', terms = '' }) => {
  const [amount, setAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false); 
  const [loadingMessage, setLoadingMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true); 
  const userData = useSelector((state) => state.user.userData);

  const validateAmount = () => {
    if (paymentMethod === "PayPal") {
      if ((userData.currency === "R" && (amount < 100 || amount > 1000)) ||
          (userData.currency === "$" && (amount < 10 || amount > 1000))) {
        setErrorMessage(`Amount for PayPal must be between ${userData.currency === "R" ? "R100 and R1000" : "$10 and $1000"}.`);
        setIsSubmitDisabled(true);
        return;
      }
    } else if (paymentMethod === "Internet Banking" && (amount < 20 || amount > 2000)) {
      setErrorMessage("Amount for this method must be between R20 and R2000.");
      setIsSubmitDisabled(true);
      return;
    }
    setErrorMessage('');
    setIsSubmitDisabled(false);
  };

  useEffect(() => {
    validateAmount();
  }, [amount, paymentMethod]); 

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, ''); 
    setAmount(value);
  };

  const handleDeposit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const endpoint = paymentMethod === "PayPal" ? "paypal-deposit" : "process-deposit";

    setIsLoading(true);  
    setLoadingMessage('Processing your deposit, this may take a few seconds.');

    try {
      const response = await axios.post(
        `${url}/api/Payment/${endpoint}`,
        { fullName, surname, email, walletAddress, amount },
        { withCredentials: true, headers: { Authorization: `Bearer ${token}` } }
      );
      
      if (response.status === 200) {
        setLoadingMessage('Redirecting to Payment page...');
        if (response.data.redirectUrl || response.data.approvalUrl) {
          window.location.href = response.data.redirectUrl || response.data.approvalUrl;
        } else {
          setLoadingMessage('Deposit processed successfully!');
        }
      }
    } catch (err) {
      setErrorMessage('An error occurred while processing your deposit. Please try again.');
      console.error('Deposit error:', err);
    } 
  };

  return (
    <div className="deposit-container">
      <Loading showModal={isLoading} message={loadingMessage} onClose={() => setIsLoading(false)} />

      <div className="deposit-content">
        <form className="deposit-form" onSubmit={handleDeposit}>
          <div className="form-group">
            <label htmlFor="method" className="form-label">Method</label>
            <input type="text" id="method" value={paymentMethod} readOnly className="form-input read-only" />
          </div>

          <div className="form-group">
            <label htmlFor="account" className="form-label">To account</label>
            <input type="text" id="account" value={accountNumber} readOnly className="form-input read-only" />
          </div>

          <div className="form-group">
            <label htmlFor="amount" className="form-label">Amount</label>
            <input
              type="text"
              id="amount"
              className="form-input"
              value={amount ? `${userData.currency} ${amount}` : ''}
              placeholder={`${userData.currency} 0.00`}
              onChange={handleAmountChange}
            />
            {errorMessage && <p className="error-message" aria-live="polite">{errorMessage}</p>}
          </div>

          <button type="submit" className="submit-button" disabled={isSubmitDisabled}>
            Submit
          </button>
        </form>

        <div className="deposit-terms">
          <div className="terms-line"></div>
          <div className="terms-content">
            <h4 className="terms-title">Terms</h4>
            <p className="terms-details">{terms}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deposits;
