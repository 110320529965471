import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Deposit.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from 'react-redux';
import Deposits from "../../components/Deposits/deposits";
import url from "../../components/endpoint";
import Auth from "../Login/Auth";


const Deposit = ({ showSidebar, active, closeSidebar }) => {
  const [loading, setLoading] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const userData = useSelector((state) => state.user.userData) || {};
  const { fullName, surname, email, walletAddress , accountNumber } = userData;

  
  const handlePaymentMethodClick = (method) => {
    setSelectedMethod(method);
  };

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      const token = localStorage.getItem('token');
      setLoading(true);
      try {
        const response = await axios.get(`${url}/api/Payment/DepositMethods`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        
        
        setPaymentMethods(response.data.value.methods);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching payment methods:", error);
        toast.error("Failed to load payment methods.");
        setLoading(false);
      }
    };
  
    fetchPaymentMethods();
  }, []);
  
  

  return (
    <div className="deposit">
      <ToastContainer />
      <Sidebar active={active} closeSidebar={closeSidebar} />
      <div className="deposit_container">
        <Navbar showSidebar={showSidebar} />
        <div className="content">
    
        {!selectedMethod && (
  <div className="deposit_form">
    <h2>All Deposit Methods</h2>
    <div className="payment-methods">
      {loading
        ? Array.from({ length: 6 }).map((_, index) => (
            <Skeleton
              key={index}
              height={150}
              width={230}
              style={{ margin: "1rem" }}
            />
          ))
        : paymentMethods.map((method) => (
            <div
              key={method.id}
              className={`payment-card ${!method.active ? 'disabled' : ''}`}
              aria-label={`Select ${method.name} Deposit Method`}
              style={{
                pointerEvents: !method.active ? "none" : "auto",
                opacity: !method.active ? 0.5 : 1,
              }}
              onClick={() => handlePaymentMethodClick(method)} 
            >
              {method.recommended && method.active ? (
                <div className="badge">Recommended</div>
              ) : !method.active ? (
                <div className="coming-soon" style={{ color: "red" }}>
                  Coming Soon
                </div>
              ) : null}
              <img src={method.logo} alt={`${method.name} Logo`} />
              <div>
                <h3>{method.name}</h3>
                <p>{method.details}</p>
              </div>
            </div>
          ))}
    </div>
  </div>
)}
            {selectedMethod && (
              <Deposits
                paymentMethod={selectedMethod.name}
                accountNumber={accountNumber}
                fullName = {fullName}
                surname= {surname}
                email={email}
                walletAddress={walletAddress}
                terms={selectedMethod.details}
              />
            )}
          </div>
        </div>
      </div>
   
  );
};

export default Deposit;
