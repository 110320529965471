import React, { useState } from 'react';
import './LanguageModal.scss';

const LanguageModal = ({ showModal, onClose, onLanguageSelect }) => {
  const [selectedLanguage, setSelectedLanguage] = useState('');

  const handleSelectLanguage = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const handleContinue = () => {
   
    onLanguageSelect(selectedLanguage);
    onClose(); 
  };

  if (!showModal) return null;

  return (
    <div className="language-modal-overlay" onClick={onClose}>
      <div className="language-modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Select Your Programming Language</h2>
        
        <div className="form-group">
          <select
            value={selectedLanguage}
            onChange={handleSelectLanguage}
            className="language-select"
          >
            <option value="">Select a language</option>
            <option value="JavaScript">JavaScript</option>
            <option value="Python">Python</option>
            <option value="Java">Java</option>
            <option value="C++">C++</option>
            <option value="C#">C#</option>
            
          </select>
        </div>

        <div className="footer">
          <p>Select Language</p>
        </div>

        <button
          onClick={handleContinue}
          className={`continue-button ${selectedLanguage ? 'active' : ''}`}
          disabled={!selectedLanguage}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default LanguageModal;
