import React, { useState, useEffect } from 'react';
import './Success.scss';

const Success = ({ message, showModal, onClose }) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (hasLoaded) {
      // Wait 2 seconds before refreshing the page
      const timer = setTimeout(() => {
        window.location.reload();
      }, 2000);

      // Clear timeout if the component is unmounted
      return () => clearTimeout(timer);
    }
  }, [hasLoaded]);

  if (!showModal) return null;

  const handleImageLoad = () => {
    // Mark as loaded when the GIF is fully loaded
    setHasLoaded(true);
  };

  return (
    <div className="loading-overlay" onClick={onClose}>
      <div className="loading-content" onClick={(e) => e.stopPropagation()}>
        <img 
          src="/check-green.gif" 
          alt="Icon" 
          className="loading-icon" 
          onLoad={handleImageLoad}
        />
        <p className="loading-message">{message}</p>
      </div>
    </div>
  );
};

export default Success;
