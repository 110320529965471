import "./sidebar.scss";
import "../../App.scss";
import { Link } from "react-router-dom";
import { IoHome, IoLogOut, IoSend } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { GiWallet } from "react-icons/gi";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GameModal from '../../Pages/Modals/GameModal';
import { setGameStatusFalse, clearGameData } from '../../redux/actions/userActions'; 
import url from "../endpoint";
import * as signalR from "@microsoft/signalr";
import { Bars } from "react-loader-spinner";
import Log from "../../Pages/Modals/log";
import axios from "axios";

const Sidebar = ({ active, closeSidebar }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  const [timeLeft, setTimeLeft] = useState(0); 
  const [isGameFinished, setIsGameFinished] = useState(false);
  const {status, gameData, userCode} = useSelector((state) => state.game);
  const [showModal, setShowModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);
  const [serverLogs, setServerLogs] = useState([]);

  const handleTryAgain = () => {
    setShowModal(false);
    dispatch(clearGameData());
    setTimeLeft(0); 
    window.location.href='https://play929.com';
  };

  // SignalR connection
  const [connection, setConnection] = useState(null);

  useEffect(() => {
    if (status && gameData?.signature && gameData?.signaturePayload && gameData?.gameSessionID) {
      const connectionInstance = new signalR.HubConnectionBuilder()
        .withUrl(`${url}/gameHub?signature=${gameData.signature}&payload=${gameData.signaturePayload}&gameSessionID=${gameData.gameSessionID}`)
        .build();
  
      connectionInstance
        .start()
        .then(() => console.log("Connection established"))
        .catch((err) => console.error("Connection failed: ", err));
  
      connectionInstance.onclose((err) => {
        console.error("Connection closed:", err);
        // Attempt to reconnect
        setTimeout(() => startConnection(), 5000);
      });
  
      connectionInstance.on("CountdownTimer", (time) => {
        setTimeLeft(time);
      });
  
      connectionInstance.on("CountdownFinished", () => {
        dispatch(setGameStatusFalse());
        dispatch(clearGameData());
        setShowModal(true);
        setIsGameFinished(true);
      });
  
      connectionInstance.on("Error", (message) => {
        console.error("Error:", message);
      });
  
      connectionInstance.on("ValidationLog", (log) => {
        setServerLogs((prevLogs) => [
          ...prevLogs,
          { message: log.message, status: log.status }
        ]);
      });
  
      setConnection(connectionInstance);
    }
  
    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, [status, gameData ]);
  
  const startConnection = () => {
    if (connection) {
      connection.start()
        .then(() => console.log("Reconnection successful"))
        .catch((err) => console.error("Reconnection failed:", err));
    }
  };
  

  const handleSubmit = async () => {
    setShowLogModal(true); 
    setServerLogs([{ message: "Submitting user code...", status: "pending" }]);
  
    if (connection) {
      try {
        console.log("userCode", userCode);
        await connection.invoke("ValidateUserCode", userCode);
  
        // Update logs on success
        setServerLogs((prevLogs) => [
          ...prevLogs,
          { message: "Code submitted successfully!", status: "success" },
        ]);
      } catch (err) {
        console.error("Error submitting user code:", err);
        setServerLogs((prevLogs) => [
          ...prevLogs,
          { message: "Error submitting code.", status: "error" },
        ]);
      }
    }
  };
  
  

  // Closing the log modal
  const closeLogModal = () => {
    setShowLogModal(false);
  };

  const handleLogout = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
  
    try {
     
      const response = await axios.post(
        `${url}/api/Account/Logout`,
        {}, 
        {
          headers: {
            Authorization: `Bearer ${token}`, 
          },
          withCredentials: true, 
        }
      );
  
       window.location.href = "";
  
    } catch (err) {
      
      console.error("Logout error:", err);
    } finally {
     
      localStorage.clear();
      
    }
  };
  
  const startCountDown = () => {
    if (connection && connection.state === "Connected" && gameData?.signature && gameData?.signaturePayload) {
      connection
        .invoke("StartGame", gameData.signature, gameData.signaturePayload)
        .then(() => console.log("Game started"))
        .catch((err) => console.error("Error starting game:", err));
    } else {
      console.error("Connection not established or invalid data.");
    }
  };
  

  useEffect(() => {
    if (status && connection && connection.state === "Connected") {
      startCountDown(); 
    }
  }, [status, connection ,connection?.state ]);

  // Format time in mm:ss format
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <aside className={`sidebar ${active}`}>
      {loading && (
         <div
         style={{
             position: "fixed",
             top: 0,
             left: 0,
             width: "100vw",
             height: "100vh",
             backgroundColor: "rgba(0, 0, 0, 0.5)",
             display: "flex",
             justifyContent: "center",
             alignItems: "center",
             zIndex: 9999,
         }}
     >
         <Bars height="80" width="80" color="#fff" ariaLabel="bars-loading" visible={true} />
     </div>
      )}
      <div className="top">
        <h3>Play929.com</h3>
        <div className="close_btn" onClick={closeSidebar}>
          &times;
        </div>
      </div>
  
      <div className="middle">
        {status ? (
          <>
            <div
              className={`countdown-timer ${
                timeLeft < 30 ? "red-timer" : "green-timer"
              }`}
            >
              {formatTime(timeLeft)}
            </div>
  
            {isGameFinished && <div className="game-finished-message">Game Over!</div>}
  
            <Link
              className={activeItem === "submit" ? "link active" : "link"}
              onClick={handleSubmit}
            >
              <IoSend className="icon" />
              <span>Submit</span>
            </Link>
          </>
        ) : (
          <>
            <Link
              onClick={() => setActiveItem("home")}
              className={activeItem === "home" ? "link active" : "link"}
              to="/dashboard"
            >
              <IoHome className="icon" />
              <span>Home</span>
            </Link>
  
            <Link
              onClick={() => setActiveItem("wallet")}
              className={activeItem === "wallet" ? "link active" : "link"}
              to="/wallets"
            >
              <GiWallet className="icon" />
              <span>Wallet</span>
            </Link>
  
            <Link className="link" to="/profile">
              <FaUser className="icon" />
              <span>Profile</span>
            </Link>
  
            <Link
              className="link"
              to=""
              onClick={() => {
                closeSidebar();
                handleLogout();
              }}
            >
              <IoLogOut className="icon" />
              <span>Logout</span>
            </Link>
          </>
        )}
      </div>
  
      {showModal && (
        <GameModal
          message="Game Over! Go back?"
          onTryAgain={handleTryAgain}
        />
      )}
      <Log show={showLogModal} onClose={closeLogModal} serverLogs={serverLogs} />; 
    </aside>
  );
};  

export default Sidebar;
