import React from "react";

const PrivacyPolicy = () => (
  <div>
    <h1>Privacy and Cookie Policy</h1>
    <p>
      We use cookies to enhance your browsing experience. Cookies are small
      text files stored on your device. They help us improve the website and
      provide personalized services.
    </p>
    <p>
      You can disable cookies in your browser settings, but some features may
      not work as intended.
    </p>
  </div>
);

export default PrivacyPolicy;
