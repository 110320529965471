import React, { useState } from "react";
import "./withdrawals.scss";
import Loading from "../Loading/loading";
import url from "../endpoint";
import { useSelector } from "react-redux";
import axios from "axios";
import ErrorModal from "../../Pages/Modals/ErrorModal";
import Success from "../../components/Success/Success";

const Withdrawals = ({ paymentMethod, fullName, surname, email, walletAddress, terms }) => {
  const [selectedBank, setSelectedBank] = useState("");
  const [amount, setAmount] = useState("");
  const [userAccountNumber, setUserAccountNumber] = useState("");
  const [paypalEmail, setPaypalEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [withdrawalSuccess, setWithdrawalSuccess] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  const [accountError, setAccountError] = useState("");
  const [amountError, setAmountError] = useState("");
  const [paypalError, setPaypalError] = useState("");
  const [errorMessage , setErrorMessage] = useState('');
  const [isOpen , setIsOpen] = useState(false);

  const handleBankChange = (e) => {
    setSelectedBank(e.target.value);
  };

  const handleCloseError = () => {
  setIsOpen(false);
    
};

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    setAmount(value);
    if (value && parseFloat(value) <= 0) {
      setAmountError("Amount must be a positive number.");
    } else {
      setAmountError("");
    }
  };

  const handleAccountNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setUserAccountNumber(value);
      setAccountError("");
    } else {
      setAccountError("Account number must contain only numbers.");
    }
  };

  const handlePaypalEmailChange = (e) => {
    const value = e.target.value;
    setPaypalEmail(value);
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      setPaypalError("Please enter a valid email address.");
    } else {
      setPaypalError("");
    }
  };

  const handleWithdrawal = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    if (amountError || accountError || paypalError) {
      alert("Please correct the errors in the form.");
      return;
    }

    if (!amount || (paymentMethod === "Internet Banking" && (!selectedBank || !userAccountNumber)) || (paymentMethod === "PayPal" && !paypalEmail)) {
      alert("Please fill out all required fields.");
      return;
    }

    setIsLoading(true);
    setLoadingMessage("Processing your withdrawal.");

    try {
      const endpoint =
        paymentMethod === "Internet Banking"
          ? `${url}/api/Payment/WithdrawInternetBanking`
          : `${url}/api/Payment/WithdrawPayPal`;

      const payload =
        paymentMethod === "Internet Banking"
          ? {
              fullName,
              surname,
              email,
              walletAddress,
              amount,
              bankName: selectedBank,
              accountNumber: userAccountNumber,
            }
          : { fullName, surname, email, amount, paypalEmail , walletAddress };

      const response = await axios.post(endpoint, payload, { withCredentials: true , headers: { Authorization: `Bearer ${token}` }});

      if (response.status === 200) {
        setWithdrawalSuccess(true);
      }
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message || "An unknown error occurred";
      setErrorMessage(errorMessage);
      setIsOpen(true);
      console.error("Withdrawal error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="withdrawal-container">
      <Loading showModal={isLoading} message={loadingMessage} onClose={() => setIsLoading(false)} />
      <Success showModal={withdrawalSuccess} message="Withdrawal Successful" onClose={() => setWithdrawalSuccess(false)} />
      <div className="withdrawal-content">
        <form className="withdrawal-form" onSubmit={handleWithdrawal}>
          <div className="form-group">
            <label htmlFor="method" className="form-label">Method</label>
            <input type="text" id="method" value={paymentMethod} readOnly className="form-input read-only" />
          </div>

          {paymentMethod === "Internet Banking" && (
            <>
              <div className="form-group">
                <label htmlFor="account" className="form-label">Bank Account</label>
                <input
                  type="text"
                  id="account"
                  value={userAccountNumber}
                  onChange={handleAccountNumberChange}
                  placeholder="Account Number"
                  className="form-input"
                />
                {accountError && <span className="error-message">{accountError}</span>}
              </div>
              <div className="form-group">
                <label htmlFor="bank" className="form-label">Select Bank</label>
                <select
                  id="bank"
                  value={selectedBank}
                  onChange={handleBankChange}
                  className="form-input"
                >
                  <option value="">Select Bank</option>
                  <option value="ABSA">ABSA</option>
                  <option value="Capitec Bank">Capitec Bank</option>
                  <option value="First National Bank">First National Bank (FNB)</option>
                  <option value="Nedbank">Nedbank</option>
                  <option value="Standard Bank">Standard Bank</option>
                  <option value="Investec">Investec</option>
                  <option value="TymeBank">TymeBank</option>
                  <option value="African Bank">African Bank</option>
                  <option value="Bidvest Bank">Bidvest Bank</option>
                </select>
              </div>
            </>
          )}

          {paymentMethod === "PayPal" && (
            <div className="form-group">
              <label htmlFor="paypalEmail" className="form-label">PayPal Email</label>
              <input
                type="email"
                id="paypalEmail"
                value={paypalEmail}
                onChange={handlePaypalEmailChange}
                placeholder="PayPal Email"
                className="form-input"
              />
              {paypalError && <span className="error-message">{paypalError}</span>}
            </div>
          )}

          <div className="form-group">
            <label htmlFor="amount" className="form-label">Amount</label>
            <input
              type="text"
              id="amount"
              className="form-input"
              value={amount ? `${userData.currency} ${amount}` : ""}
              placeholder={`${userData.currency} 0.00`}
              onChange={handleAmountChange}
            />
            {amountError && <span className="error-message">{amountError}</span>}
          </div>

          <button type="submit" className="submit-button">Submit</button>
        </form>

        <div className="withdrawal-terms">
          <div className="terms-line"></div>
          <div className="terms-content">
            <h4 className="terms-title">Terms</h4>
            <p className="terms-details">{terms}</p>
          </div>
        </div>
      </div>
      <ErrorModal errorMessage={errorMessage} isOpen={isOpen} onClose={handleCloseError} />;
    </div>
  );
};

export default Withdrawals;
