import React from 'react';
import { Link } from 'react-router-dom'; 
import './GameError.scss';

const GameError = ({ message, onDepositClick }) => {
  return (
    <div className="game-error-modal">
      <div className="game-error-content">
        <p className="error-message">{message}</p>
        <p className="error-footer">Please Deposit</p>
      
        <Link to="/deposit">
          <button className="deposit-button" onClick={onDepositClick}>
            Deposit
          </button>
        </Link>
      </div>
    </div>
  );
};

export default GameError;
