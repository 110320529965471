import React, { useState, useEffect, useRef } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import { FiLoader } from "react-icons/fi";
import url from "../../components/endpoint";
import {Comment} from "react-loader-spinner";
import './chatbox.scss';

const Chatbox = ({ showSidebar, active, closeSidebar }) => {
  const [messages, setMessages] = useState([
    { text: 'Connecting...', sender: 'bot' }
  ]);
  const [newMessage, setNewMessage] = useState('');
  const messageEndRef = useRef(null);
  const [connection, setConnection] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const connect = async () => {
      setLoading(true);
      const conn = new HubConnectionBuilder()
        .withUrl(`${url}/chathub`) 
        .build();

      conn.on("ReceiveMessage", (message) => {
        const botMessage = { text: message, sender: 'bot' };
        setMessages(prevMessages => [...prevMessages, botMessage]);
      });

      try {
        await conn.start();
        console.log("Connected to SignalR");
      } catch (err) {
        console.error("Error connecting to SignalR:", err);
      }

      setConnection(conn);
      setLoading(false);
    };

    connect();

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, []);

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    const userMessage = { text: newMessage, sender: 'user' };
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setNewMessage('');

    // Send the message to the server
    if (connection) {
      await connection.invoke("SendMessage", newMessage);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  return (
    <div className="chatbox-wrapper">
      {loading && (
        <div className="overlay">
          
                            <div
                                style={{
                                position: "fixed",
                                top: 0,
                                left: 0,
                                width: "100vw",
                                height: "100vh",
                                backgroundColor: "rgba(0, 0, 0, 0.5)", 
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: 9999, 
                                }}
                            >
                                <Comment
                                height="80"
                                width="80"
                                color="#4fa94d"
                                ariaLabel="bars-loading"
                                visible={true}
                                />
                            </div>
                        
        </div>
      )}
      <Sidebar active={active} closeSidebar={closeSidebar} />
      <div className="chatbox-container">
        <Navbar showSidebar={showSidebar} />
        <div className="chatbox-header">
          <h3>Customer Support</h3>
        </div>
        <div className="chatbox-messages">
          {messages.map((message, index) => (
            <div key={index} className={`chatbox-message ${message.sender === 'user' ? 'user' : 'bot'}`}>
              {message.text}
            </div>
          ))}
          <div ref={messageEndRef} />
        </div>
        <div className="chatbox-input-container">
          <input
            type="text"
            placeholder="Type a message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default Chatbox;
