import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Withdraw.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Withdrawals from "../../components/Withdrawals/withdrawals";
import url from "../../components/endpoint";
import { useSelector } from "react-redux";

const Withdraw = ({ showSidebar, active, closeSidebar }) => {
  const [withdrawalMethod, setWithdrawalMethod] = useState("");
  const userData = useSelector((state) => state.user.userData) || {};
  const {  fullName, surname, email, walletAddress, accountNumber } = userData;

  const [loading, setLoading] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const handleWithdrawMethod = (method) => {
    setWithdrawalMethod(method);
  };

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      const token = localStorage.getItem('token');
      setLoading(true);
      try {
        const response = await axios.get(`${url}/api/Payment/DepositMethods`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        
        
        setPaymentMethods(response.data.value.methods);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching payment methods:", error);
        toast.error("Failed to load payment methods.");
        setLoading(false);
      }
    };
  
    fetchPaymentMethods();
  }, []);

  return (
    <div className="withdraw">
      <ToastContainer />
      <Sidebar active={active} closeSidebar={closeSidebar} />
      <div className="withdraw_container">
        <Navbar showSidebar={showSidebar} />
        <div className="content">
          
            {withdrawalMethod === "" ? (
              
              <>
              <div className="withdraw_form">
                <h2>All Withdrawal Methods</h2>
                <div className="payment-methods">
                  {loading
                    ? Array.from({ length: 6 }).map((_, index) => (
                        <Skeleton key={index} height={150} width={230} style={{ margin: "1rem" }} />
                      ))
                    : paymentMethods.map((method) => (
                        <div
                          key={method.id}
                          className={`payment-card ${!method.recommended ? "disabled" : ""}`}
                          aria-label={`Select ${method.name} Withdrawal Method`}
                          style={{
                            pointerEvents: !method.recommended ? "none" : "auto",
                            opacity: !method.recommended ? 0.5 : 1,
                          }}
                          onClick={() => handleWithdrawMethod(method)}
                        >
                          {method.recommended ? (
                            <div className="badge">Recommended</div>
                          ) : (
                            <div className="coming-soon" style={{ color: "red" }}>
                              Coming Soon
                            </div>
                          )}
                          <img src={method.logo} alt={`${method.name} Logo`} />
                          <div>
                            <h3>{method.name}</h3>
                            <p>{method.details}</p>
                          </div>
                        </div>
                      ))}
                </div>
                </div>
              </>
            ) : (
              <Withdrawals
                paymentMethod={withdrawalMethod.name}
                accountNumber={accountNumber}
                fullName = {fullName}
                surname= {surname}
                email={email}
                walletAddress={walletAddress}
                terms={withdrawalMethod.details}
              />
            )}
          </div>
        </div>
      </div>
    
  );
};

export default Withdraw;
