import React, { useEffect, useState } from 'react';
import Loading from "../../components/Loading/loading";
import axios from "axios";
import url from "../../components/endpoint";

const Success = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState('Processing Your Deposit, Please Do not Close this Window.');

    useEffect(() => {
      
        const urlParams = new URLSearchParams(window.location.search);
        const paymentId = urlParams.get('paymentId');
        const token = urlParams.get('token');
        const payerId = urlParams.get('PayerID');

        if (paymentId && token && payerId) {
            processPayment({ paymentId, token, payerId });
        } else {
            setLoadingMessage('Invalid payment details. Please try again.');
            setIsLoading(false);
        }
    }, []);

    const processPayment = async (data) => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.post(
                `${url}/api/Payment/paypal-processing`,
                data,
                {
                  headers: {
                    'Authorization': `Bearer ${token}`, 
                  },
                  withCredentials: true, 
                }
              );
              
              if (response.status === 200) {
                setLoadingMessage('Deposit processed successfully. Redirecting...');
                setTimeout(() => {
                  setIsLoading(false);
                  window.location.href = 'https://play929.com';
                }, 3000);
              
              
            } else {
                setLoadingMessage('Failed to process the deposit. Please try again.');
                
            }
        } catch (error) {
            console.error('Error processing payment:', error);
            setLoadingMessage('An error occurred. Please contact support.');
           
        }
    };

    return (
        <div className="deposit-container">
            <Loading 
                showModal={isLoading} 
                message={loadingMessage} 
                onClose={() => setIsLoading(false)} 
            />
        </div>
    );
};

export default Success;
