import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData, clearError } from '../../redux/actions/userActions';
import './Navbar.scss';
import Error from "../../Pages/Modals/ErrorModal";
import Auth from "../../Pages/Login/Auth";
import { Bars } from "react-loader-spinner";
import url from "../endpoint";

const Navbar = () => {
    const dispatch = useDispatch();
    const { userData, loading, error } = useSelector((state) => state.user);
    const { gameData } = useSelector((state) => state.game);
    const [isAuthOpen, setIsAuthOpen] = useState(false);

    useEffect(() => {
        if ((!userData && !loading) || error) {
            setIsAuthOpen(true); 
        } else {
            setIsAuthOpen(false); 
        }
    }, [userData, loading, error]);

    useEffect(() => {
        if (!userData && !loading && !error) {
            dispatch(fetchUserData()); 
        }
    }, [dispatch, userData, loading, error]);

    const handleCloseError = () => {
        dispatch(clearError());
    };

    const renderContent = () => {
        if (loading || !userData) {
            return (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                    }}
                >
                    <Bars height="80" width="80" color="#fff" ariaLabel="bars-loading" visible={true} />
                </div>
            );
        }

        if (error) {
            return <Error errorMessage={error} isOpen={true} onClose={handleCloseError} />;
        }

        if (gameData?.question) {
            return (
                <div className="game-container">
                    <div className="game-question">
                        <h1>{gameData.question}</h1>
                    </div>
                </div>
            );
        }

        return (
            <div className="user-info">
                <div className="balance">
                    <div className="number">{userData.currency}{userData?.balance || '0'}</div>
                </div>

                <div className="account-number">
                    
                    <div className="number">{userData?.accountNumber || ''}</div>
                </div>
            </div>
        );
    };

    return (
        <>
            <header className="navbar">
                <div className="navbar-content">
                    {renderContent()}
                </div>
            </header>

            {isAuthOpen && (
                <Auth
                    isOpen={isAuthOpen}
                    onClose={() => setIsAuthOpen(false)}
                    url={url}
                />
            )}
        </>
    );
};

export default Navbar;
