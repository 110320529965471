import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import "./CookieConsent.css";

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consentGiven = Cookies.get("cookieConsent");
        console.log("CookieConsent component loaded, consent status:", consentGiven); // Debugging log
        // Show the popup if the consent cookie is not set (i.e., undefined or null)
        if (consentGiven === undefined || consentGiven === null) {
            setIsVisible(true);
            console.log("executed setIsvisible" , isVisible)
        }
    }, []);

    const acceptCookies = () => {
        Cookies.set("cookieConsent", "true", { expires: 365, path: "/" });
        console.log("User accepted cookies"); // Debugging log
        setIsVisible(false);
    };

    return (
        isVisible && (
            <div className="cookie-banner">
                <p>We use cookies to improve your experience. By continuing, you agree to our cookie policy.</p>
                <button onClick={acceptCookies}>Accept</button>
            </div>
        )
    );
};

export default CookieConsent;
